<template>
  <div class="container">
    <div class="title">
      <h3>Forgot Username</h3>
    </div>
    <div v-if="errMsg !== ''">
      <p class="err">{{ errMsg }}</p>
    </div>
    <div class="success" v-if="employeeID !== ''">
      <h4>
        Your Employee ID is: <u>{{ employeeID }}</u>
      </h4>
    </div>
    <p>Please enter the date of birth and last four digits of the SSN associated with your account.</p>
    <form action="#" class="form">
      <fieldset :disabled="loading">
        <div class="DOB">
          <label for="dateofbirth" class="label">DATE OF BIRTH*</label>
          <input id="DOB" type="date" name="DOB" class="input" min="1900-01-01" max="2021-01-01" step="1" v-model="DOB" required />
        </div>
        <div class="SSN">
          <label for="LastFourOfSSN" class="label">LAST FOUR # OF SSN*</label>
          <input id="SSN" type="text" name="SSN" class="input" maxlength="4" pattern="\d{4}" v-model="SSN" required />
        </div>
        <div class="submit">
          <input type="submit" class="button" v-on:click.prevent="submitform()" v-if="!loading"/>
        </div>
        <div v-show="loading">
          <b-button class = "button">
            <b-spinner small></b-spinner>
            Loading...
          </b-button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import EmployeeIDService from '@/services/EmployeeIDService.js';

export default {
  name: 'EmployeeIDLookUp',
  data() {
    return {
      DOB: '',
      SSN: '',
      employeeID: '',
      errMsg: '',
      loading: false,
    };
  },
  methods: {
    async submitform() {
      this.loading = true;
      this.employeeID = '';
      this.errMsg = '';

      if (this.DOB == '' || this.SSN == '') {

        this.errMsg = 'Please fill in all the required fields.';
        this.loading = false;
      } else {
        //call the service and send DOB & SSN
        await EmployeeIDService.getEmployeeID(this.DOB, this.SSN)
          .then((response) => {
            if (response.status == 200) {
              if (response.data.employeeID == '') {
                this.errMsg = 'Employee ID not found.';
                this.loading = false;
              } else {
                this.employeeID = response.data.employeeID;
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              this.errMsg = 'Error retrieving Employee ID.';
            }
          });

        
        //clear fields
        this.DOB = '';
        this.SSN = '';
      }
      if (this.employeeID != ''){
          this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.err {
  color: red;
  font-size: 1em;
  font-weight: lighter;
  margin-bottom: 20px;
}

.success {
  font-size: 1.5em;
  font-weight: lighter;
  margin-bottom: 20px;
  color: rgba(33, 146, 188, 255);
}
</style>
