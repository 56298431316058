import Vue from 'vue'
import VueRouter from 'vue-router'
import EmployeeIDLookUp from '../components/EmployeeIDLookUp.vue'


Vue.use(VueRouter)


export default new VueRouter({

  routes: [
    {
        path: '/employeeid',
        name: 'employeeIDLookUp',
        component: EmployeeIDLookUp
      },

  ]
})
