import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue';
import './plugins/bootstrap-vue'
import App from './App.vue';
import router from './router';
import { nonPciApiHost } from './config';
import axios from 'axios';
import { SpinnerPlugin } from 'bootstrap-vue'
import BootstrapVue from "bootstrap-vue";

Vue.config.productionTip = false;

Vue.use(SpinnerPlugin)

Vue.use(BootstrapVue)


//importing global css file
import '@/assets/global.css';

axios.defaults.baseURL = nonPciApiHost;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
